import ColorsAPI from "../../../../admin/src/Views/StylesConfig/compositionAPI/ColorsAPI";

import {
  hexToRgb,
  mixBlack,
  mixWhite,
} from "aloha-vue/src/utils/colors";

import {
  assign,
  forEach,
  map,
  startsWith,
} from "lodash-es";

const VARS_MAPPING = {
  "$grey-1": "a_color_gray_100",
  "$grey-2": "a_color_gray_200",
  "$grey-3": "a_color_gray_300",
  "$grey-4": "a_color_gray_400",
  "$grey-5": "a_color_gray_500",
  "$grey-6": "a_color_gray_600",
  "$grey-7": "a_color_gray_700",
  "$grey-8": "a_color_gray_800",
  "$grey-9": "a_color_gray_900",
  "$primary-color": "a_color_primary",
  "$secondary-color": "a_color_secondary",
  "$tertiary-color": "a_color_tertiary",
  "$success-color": "a_color_success",
  "$info-color": "a_color_info",
  "$warning-color": "a_color_warning",
  "$danger-color": "a_color_danger",
  "$text-color": "a_color_text",
  "$link-color": "a_color_link",
  $black: "a_color_black",
  $white: "a_color_white",
  "$border-radius": "a_border_radius",
  "$border-radius-small": "a_border_radius_small",
  "$border-radius-large": "a_border_radius_large",
  "$border-radius-xl": "a_border_radius_xl",
  "$border-radius-2xl": "a_border_radius_2xl",
};

const MAIN_COLORS = [
  "a_color_primary",
  "a_color_secondary",
  "a_color_tertiary",
  "a_color_success",
  "a_color_info",
  "a_color_warning",
  "a_color_danger",
];

const ALERT_TYPES = [
  "success",
  "info",
  "warning",
  "danger",
];

const GUTACHTEN_COLORS_BASE = {
  primary: "$primary-color",
  secondary: "$secondary-color",
  danger: "$danger-color",
  success: "$success-color",
  warning: "$warning-color",
  info: "$info-color",
  light: "$grey-2",
  dark: "$grey-7",
  "grey-1": "$grey-2",
  "grey-2": "$grey-3",
  "grey-3": "$grey-4",
  "grey-4": "$grey-5",
  "grey-5": "$grey-6",
  "grey-6": "$grey-7",
  orange: "$orange",
  green: "$green",
  red: "$red",
  "light-blue": "$light-blue",
  "dark-blue": "$dark-blue",
  "dark-red": "$dark-red",
  "dark-green": "$dark-green",
};

export default class Styles {
  constructor({
    model,
    globalVars,
    name,
    type,
  }) {
    this.model = model;
    this.globalVars = globalVars;
    this.name = name;
    this.type = type;

    if (this.type !== "colors" && this.type !== "border-radius") {
      this.modelWithColors = this.setModelWithColors();
    }

    this.addCurrentStylesInHeader();
  }

  setModelWithColors() {
    const MODEL = {};
    forEach(this.model, (item, key) => {
      if (item.to_rgb || item.mix) {
        MODEL[key] = this.getMixOrRgbColor({
          mix: item.mix,
          parent: item.parent,
          percent: item.percent,
          value: item.value,
          toRgb: item.to_rgb,
        });
      } else {
        if (startsWith(item.value, "$")) {
          let keyValue = VARS_MAPPING[item.value] || item.value;
          keyValue = startsWith(keyValue, "$") ?
            keyValue.substring(1) :
            keyValue;
          MODEL[key] = `var(--${ keyValue })`;
        } else {
          MODEL[key] = item.value;
        }
      }
    });
    return MODEL;
  }

  getMixOrRgbColor({ mix, parent, percent, toRgb, value }) {
    let color = "";
    if (mix) {
      const KEY_PARENT = VARS_MAPPING[parent] || parent;
      const COLOR_FROM_PARENT = this.globalVars[KEY_PARENT];
      if (mix === "white") {
        color = mixWhite(COLOR_FROM_PARENT, percent);
      } else {
        color = mixBlack(COLOR_FROM_PARENT, percent);
      }
    }
    if (toRgb) {
      if (color) {
        color = hexToRgb(color, true);
      } else if (parent) {
        const KEY_PARENT = VARS_MAPPING[parent] || parent;
        const COLOR_FROM_PARENT = this.globalVars[KEY_PARENT];
        color = hexToRgb(COLOR_FROM_PARENT, true);
      } else if (startsWith(value, "$")) {
        const KEY_VALUE = VARS_MAPPING[value] || value;
        const COLOR_FROM_VALUE = this.globalVars[KEY_VALUE];
        color = hexToRgb(COLOR_FROM_VALUE, true);
      } else {
        color = hexToRgb(value, true);
      }
    }
    return color;
  }


  addCurrentStylesInHeader() {
    const STYLES_LOCAL = this[`getStyles_${ this.name }`]();
    const STYLE_ID = this.getStyleId();
    const STYLE_TEG = `<style id="${ STYLE_ID }">${ STYLES_LOCAL }</style>`;
    const CURRENT_STYLE = $(`#${ STYLE_ID }`);
    if (CURRENT_STYLE.length) {
      CURRENT_STYLE.replaceWith(STYLE_TEG);
    } else {
      $("head").append(STYLE_TEG);
    }
  }

  getStyleId() {
    return `styles_${ this.name }${ this.type ? `_${ this.type }` : "" }`;
  }

  getStyles_vars() {
    let rootVars = "";
    forEach(this.model, (value, key) => {
      const varName = startsWith(key, "$") ?
        key.substring(1) :
        key;
      rootVars += `--${ varName }: ${ value };`;
    });
    forEach(MAIN_COLORS, colorKey => {
      if (this.model[colorKey]) {
        rootVars += `--${ colorKey }_text_emphasis: ${ mixBlack(this.model[colorKey], 60) };`;
        rootVars += `--${ colorKey }_bg_subtle: ${ mixWhite(this.model[colorKey], 80) };`;
        rootVars += `--${ colorKey }_border_subtle: ${ mixWhite(this.model[colorKey], 60) };`;
      }
    });
    const buttonAlertStyles = map(ALERT_TYPES, type => {
      const color = this.model[`a_color_${ type }`];
      const contrastColor = ColorsAPI().makeContrastColor(color, true);
      const isContrastColorLight = contrastColor === "#FFFFFF";
      const mixedColor = isContrastColorLight ? mixWhite(color, 20) : mixBlack(color, 20);
      const rgbMixedColor = this.getMixOrRgbColor({
        value: mixedColor,
        toRgb: true,
      });
      return `
        .a_btn_${ type } {
          --a_btn_color: ${ contrastColor };
          --a_btn_bg: ${ color };
          --a_btn_border_color: ${ color };
          --a_btn_hover_color: ${ contrastColor };
          --a_btn_hover_bg: ${ mixedColor };
          --a_btn_hover_border_color: ${ mixedColor };
          --a_btn_focus_shadow_rgb: ${ rgbMixedColor };
          --a_btn_active_color: ${ contrastColor };
          --a_btn_active_bg: ${ mixedColor };
          --a_btn_active_border_color: ${ mixedColor };
          --a_btn_active_shadow: inset 0 3px 5px rgba(0,0,0,0.125);
          --a_btn_disabled_color: ${ contrastColor };
          --a_btn_disabled_bg: ${ color };
          --a_btn_disabled_border_color: ${ color };
        }
      `;
    }).join("");
    return `:root {
      ${ rootVars };
    }
    ${ buttonAlertStyles }`;
  }

  getStyles_primary_colors() {
    return `
      :root {
        --primary-color-darken-10: ${ this.modelWithColors["primary-color-darken-10"] };
        --primary-color-lighten-15: ${ this.modelWithColors["primary-color-lighten-15"] };
        --primary-color-lighten-25: ${ this.modelWithColors["primary-color-lighten-25"] };
        --primary-color-lighten-40: ${ this.modelWithColors["primary-color-lighten-40"] };
        --primary-color-lighten-50: ${ this.modelWithColors["primary-color-lighten-50"] };
      }
    `;
  }

  getStyles_link() {
    return `
      :root {
        ${ this.modelWithColors.color !== "var(--a_color_link)" ? `--a_color_link: ${ this.modelWithColors.color };` : "" }
        --a_color_link_hover: ${ this.modelWithColors["hover-color"] };
      }
      .a_btn_link,
      a {
        text-decoration: ${ this.modelWithColors["text-decoration"] };
      }
      .a_btn_link:hover,
      .a_btn_link:focus {
        text-decoration: ${ this.modelWithColors["hover-text-decoration"] };
      }
      a {
        color: var(--a_color_link);
      }
      a:hover {
        color: var(--a_color_link_hover);
        text-decoration: ${ this.modelWithColors["hover-text-decoration"] };
      }
    `;
  }

  getStyles_alert() {
    return `
      .a_alert_${ this.type } {
        --a_alert_color: ${ this.modelWithColors.color };
        --a_alert_bg: ${ this.modelWithColors.bg };
        --a_alert_border_color: ${ this.modelWithColors["border-color"] };
      }
    `;
  }

  getStyles_tooltip() {
    return `
      .a_tooltip__container {
        --a_tooltip_bg: ${ this.modelWithColors.bg };
        --a_tooltip_color: ${ this.modelWithColors.color };
        --a_tooltip_font_size: ${ this.modelWithColors["font-size"] };
        --a_tooltip_max_height: ${ this.modelWithColors["max-height"] };
        --a_tooltip_padding_x: ${ this.modelWithColors["padding-x"] };
        --a_tooltip_padding_y: ${ this.modelWithColors["padding-y"] };
        --a_tooltip_box_shadow_color: ${ this.modelWithColors["box-shadow-color"] };

        font-family: ${ this.modelWithColors["font-family"] };
      }
      .tooltip-inner {
        background-color: ${ this.modelWithColors.bg };
        color: ${ this.modelWithColors.color };
      }
      .tooltip-arrow {
        background-color: ${ this.modelWithColors.bg };
      }
    `;
  }

  getStyles_footer() {
    return `
      .panel_footer {
        background-color: ${ this.modelWithColors.bg };
        color: ${ this.modelWithColors.color };
      }
      @media (min-width: 992px) {
        .panel_footer,
        .panel_footer__parent {
          min-height: ${ this.modelWithColors.height };
          height: ${ this.modelWithColors.height };
        }
      }
      .extra-footer {
        margin-bottom: ${ this.modelWithColors.height };
      }
      @media screen {
        body .page-wrapper {
          min-height: calc(100vh - ${ this.modelWithColors.height });
        }
      }
      .menu_footer__link {
        color: ${ this.modelWithColors["link-color"] };
        background-color: ${ this.modelWithColors["link-bg"] };
      }
      .menu_footer__link:hover,
      .menu_footer__link.current {
        color: ${ this.modelWithColors["hover-link-color"] };
        background-color: ${ this.modelWithColors["hover-link-bg"] };
      }
      .menu_footer__link:hover:before,
      .menu_footer__link.current:before {
        color: ${ this.modelWithColors["hover-link-color"] };
      }
      .menu_footer__link:hover .menu_footer__text,
      .menu_footer__link.current .menu_footer__text {
        border-color: ${ this.modelWithColors["hover-link-border-color"] };
        border-style: solid;
        border-top-width: ${ this.modelWithColors["hover-link-border-top-width"] };
        border-bottom-width: ${ this.modelWithColors["hover-link-border-bottom-width"] };
        border-left-width: ${ this.modelWithColors["hover-link-border-left-width"] };
        border-right-width: ${ this.modelWithColors["hover-link-border-right-width"] };
      }
    `;
  }

  getStyles_navbar() {
    return `
      .panel_menu {
        background-color: ${ this.modelWithColors.bg };
        border: 0 solid transparent;
        border-top-color: ${ this.modelWithColors["border-color-top"] };
        border-top-width: ${ this.modelWithColors["border-width-top"] };
        border-bottom-color: ${ this.modelWithColors["border-color-bottom"] };
        border-bottom-width: ${ this.modelWithColors["border-width-bottom"] };
      }
      .panel_menu__logo {
        max-height: ${ this.modelWithColors.height };
        height: ${ this.modelWithColors.height };
      }
      .panel_menu__btn {
        height: ${ this.modelWithColors["link-height"] };
      }
      .panel_menu__link {
        color: ${ this.modelWithColors["link-color"] };
        background-color: ${ this.modelWithColors["link-bg"] };
        max-height: ${ this.modelWithColors["link-height"] };
        font-weight: ${ this.modelWithColors["font-weight"] };
        font-size: ${ this.modelWithColors["font-size"] };
      }
      .panel_menu__link:before {
        color: ${ this.modelWithColors["link-color"] };
      }
      .panel_menu__link:hover {
        background-color: ${ this.modelWithColors["hover-link-bg"] };
        color: ${ this.modelWithColors["hover-link-color"] };
      }
      .panel_menu__link:hover:before {
        color: ${ this.modelWithColors["hover-link-color"] };
      }
      .panel_menu__link_dropdown {
        background-color: ${ this.modelWithColors["profile-bg"] };
        color: ${ this.modelWithColors["profile-color"] };
        max-height: ${ this.modelWithColors["link-height"] };
        font-weight: ${ this.modelWithColors["font-weight"] };
        font-size: ${ this.modelWithColors["font-size"] };
      }
      .panel_menu__link_dropdown:hover {
         background-color: ${ this.modelWithColors["hover-profile-bg"] };
         color: ${ this.modelWithColors["hover-profile-color"] };
      }
      .panel_menu__dropdown_menu__link {
        background-color: ${ this.modelWithColors["profile-bg"] };
        color: ${ this.modelWithColors["profile-color"] };
        font-weight: ${ this.modelWithColors["font-weight"] } !important;
        font-size: ${ this.modelWithColors["font-size"] };
      }
      .panel_menu__dropdown_menu__link:hover {
        background-color: ${ this.modelWithColors["hover-profile-bg"] };
        color: ${ this.modelWithColors["hover-profile-color"] };
      }
      .the-navbar-container {
        min-height: ${ this.modelWithColors["link-height"] };
      }
    `;
  }

  getStyles_button_all() {
    const fontWeightString = this.modelWithColors["font-weight"] ?
      `font-weight: ${ this.modelWithColors["font-weight"] };` :
      "";
    const focusOutlineString = this.modelWithColors["use-browser-focus"] ?
      `.a_btn:focus {
        outline: 2px solid black !important;
        box-shadow: 0px 0 0 2px white;
        outline-offset: -2px;
      }` :
      "";

    return `
      .a_btn {
        --a_btn_border_width: ${ this.modelWithColors["border-width"] };
        --a_btn_border_radius: ${ this.modelWithColors["border-radius"] };
        ${ fontWeightString }
      }
      ${ focusOutlineString }
    `;
  }

  getStyles_button() {
    let stylesLocal = `
      .a_btn_${ this.type } {
        --a_btn_color: ${ this.modelWithColors.color };
        --a_btn_bg: ${ this.modelWithColors.bg };
        --a_btn_border_color: ${ this.modelWithColors["border-color"] };
        --a_btn_hover_color: ${ this.modelWithColors["hover-color"] };
        --a_btn_hover_bg: ${ this.modelWithColors["hover-bg"] };
        --a_btn_hover_border_color: ${ this.modelWithColors["hover-border-color"] };
        --a_btn_focus_shadow_rgb: ${ this.modelWithColors["focus-shadow-rgb"] };
        --a_btn_active_color: ${ this.modelWithColors["active-color"] };
        --a_btn_active_bg: ${ this.modelWithColors["active-bg"] };
        --a_btn_active_border_color: ${ this.modelWithColors["active-border-color"] };
        --a_btn_disabled_color: ${ this.modelWithColors["disabled-color"] };
        --a_btn_disabled_bg: ${ this.modelWithColors["disabled-bg"] };
        --a_btn_disabled_border_color: ${ this.modelWithColors["disabled-border-color"] };
      }
    `;

    if (this.type === "primary") {
      stylesLocal += `
        .a_btn_outline_${ this.type } {
          --a_btn_color: var(--a_color_primary);
          --a_btn_bg: #ffffff;
          --a_btn_border_color: var(--a_color_primary);
          --a_btn_hover_color: #ffffff;
          --a_btn_hover_bg: var(--a_color_primary);
          --a_btn_hover_border_color: var(--a_color_primary);
          --a_btn_focus_shadow_rgb: ${ this.modelWithColors["focus-shadow-rgb"] };
          --a_btn_active_color: #ffffff;
          --a_btn_active_bg: var(--a_color_primary);
          --a_btn_active_border_color: var(--a_color_primary);
          --a_btn_disabled_color: ${ this.modelWithColors["disabled-color"] };
          --a_btn_disabled_border_color: ${ this.modelWithColors["disabled-border-color"] };
        }
      `;
    }
    return stylesLocal;
  }

  getStyles_proandi_menu() {
    const focusOutlineString = this.modelWithColors["use-browser-focus"] ?
      `.a_menu_2__link:focus,
       .a_menu_2__breadcrumb__ul_search .a_menu_2__link:focus {
        outline: 2px solid black !important;
        box-shadow: 0px 0 0 2px white;
        outline-offset: -2px;
      }` :
      "";
    return `
      :root {
        --a_menu_2_width_expanded: ${ this.modelWithColors.width };
      }
      .a_menu_2 {
        --a_menu_2_submenu_open_bg: ${ this.modelWithColors["active-bg"] };
        --a_menu_2_submenu_open_color: ${ this.modelWithColors["active-color"] };
        --a_menu_2_link_border_radius: ${ this.modelWithColors["border-radius"] };
        --a_menu_2_close_panel_border_radius: ${ this.modelWithColors["border-radius"] };
        --a_menu_2_submenu_border_radius: ${ this.modelWithColors["submenu-border-radius"] };
        --a_menu_2_icon_focus_shadow_color: ${ this.modelWithColors["focus-shadow-color"] };
        --a_menu_2_panel_secondary_bg: ${ this.modelWithColors["submenu-bg"] };
        --a_menu_2_link_hover_color: ${ this.modelWithColors["link-hover-color"] };
        --a_menu_2_link_hover_bg: ${ this.modelWithColors["link-hover-bg"] };
        --a_menu_2_panel_main_link_hover_color: ${ this.modelWithColors["panel-main-link-hover-color"] };
        --a_menu_2_panel_main_link_hover_bg: ${ this.modelWithColors["panel-main-link-hover-bg"] };
      }
      ${ focusOutlineString }
    `;
  }

  getStyles_antrag_steps() {
    return `
      .antrag_steps {
        background-color: ${ this.modelWithColors.bg };
      }
      .circle-box {
        border: 1px solid ${ this.modelWithColors["circle-box-border-color"] };
        color: ${ this.modelWithColors["circle-box-color"] };
      }
    `;
  }

  getStyles_beleg() {
    return `
      .beleg_abgelehnt_light {
        background-color: ${ this.modelWithColors["abgelehnt-bg-light"] };
        border: ${ this.modelWithColors["abgelehnt-border-light"] };
      }
      .beleg_annerkant_light {
        background-color: ${ this.modelWithColors["annerkant-bg-light"] };
        border: ${ this.modelWithColors["annerkant-border-light"] };
      }
    `;
  }

  getStyles_chart_colors() {
    return `
      .pux_chart__color_${ this.type },
      .dashboard__balkendiagramm__bar__${ this.type } {
        background-color: ${ this.modelWithColors.bg };
        color: ${ this.modelWithColors.color };
        box-shadow: inset 0 0 0 2px ${ this.modelWithColors.bg };
      }
      .pux_chart__color_${ this.type }:hover,
      .dashboard__balkendiagramm__bar__${ this.type }:hover {
        color: ${ this.modelWithColors.color };
      }
      .pux_chart__color_${ this.type }.disabled,
      .dashboard__balkendiagramm__bar__${ this.type }.disabled {
        color: ${ this.modelWithColors["disabled-color"] };
        background-color: ${ this.modelWithColors["disabled-bg"] };
        box-shadow: inset 0 0 0 2px ${ this.modelWithColors["disabled-bg"] };
      }
      .pux_chart__color_${ this.type }.disabled:hover,
      .dashboard__balkendiagramm__bar__${ this.type }.disabled:hover {
        color: ${ this.modelWithColors["disabled-color"] };
      }
      .bg-color-${ this.type }-stark {
        background-color: ${ this.modelWithColors.bg } !important;
      }
      .bg-color-${ this.type }-stark.disabled {
        background-color: ${ this.modelWithColors["disabled-bg"] } !important;
      }
    `;
  }

  getStyles_checkbox() {
    return `
      .custom-control,
      .ui_select__menu {
        --checkbox-border: ${ this.modelWithColors["checkbox-border"] };
        --checkbox-border-color: ${ this.modelWithColors["checkbox-border-color"] };
        --checkbox-checked-bg: ${ this.modelWithColors["checkbox-checked-bg"] };
        --checkbox-disabled-bg: ${ this.modelWithColors["checkbox-disabled-bg"] };
        --checkbox-disabled-checked-bg: ${ this.modelWithColors["checkbox-disabled-checked-bg"] };
        --checkbox-disabled-color: ${ this.modelWithColors["checkbox-disabled-color"] };
        --checkbox-hover-bg: ${ this.modelWithColors["checkbox-hover-bg"] };
        --checkbox-hover-color: ${ this.modelWithColors["checkbox-hover-color"] };
      }
    `;
  }

  getStyles_dashboard() {
    return `
      .dashboard {
        --dashboard-antrag-icon-color: ${ this.modelWithColors["dashboard-antrag-icon-color"] };
        --dashboard-antrag-icon-badge-bg: ${ this.modelWithColors["dashboard-antrag-icon-badge-bg"] };
        --dashboard-info-border-color: ${ this.modelWithColors["dashboard-info-border-color"] };
        --dashboard-naechste-sitzung-count-border: ${ this.modelWithColors["dashboard-naechste-sitzung-count-border"] };
        --dashboard-vertrag-icon-badge-bg: ${ this.modelWithColors["dashboard-vertrag-icon-badge-bg"] };
        --dashboard-vertrag-icon-color: ${ this.modelWithColors["dashboard-vertrag-icon-color"] };
        --dashboard-percent-bg: ${ this.modelWithColors["dashboard-percent-bg"] };
      }
    `;
  }

  getStyles_dashboard_colors() {
    return `
      .dashboard-${ this.type } {
        --dashboard-colors-bg: ${ this.modelWithColors.bg };
        --dashboard-colors-color: ${ this.modelWithColors.color };
        --dashboard-colors-border: ${ this.modelWithColors.border };
        --dashboard-colors-border-color: ${ this.modelWithColors["border-color"] };
        --dashboard-colors-icon-color: ${ this.modelWithColors["icon-color"] };
        --dashboard-colors-disabled-bg: ${ this.modelWithColors["disabled-bg"] };
        --dashboard-colors-disabled-color: ${ this.modelWithColors["disabled-color"] };
        --dashboard-colors-disabled-border: ${ this.modelWithColors["disabled-border"] };
        --dashboard-colors-disabled-border-color: ${ this.modelWithColors["disabled-border-color"] };
        --dashboard-colors-disabled-icon-color: ${ this.modelWithColors["disabled-icon-color"] };
        --dashboard-colors-hover-bg: ${ this.modelWithColors["hover-bg"] };
        --dashboard-colors-hover-color: ${ this.modelWithColors["hover-color"] };
        --dashboard-colors-hover-border: ${ this.modelWithColors["hover-border"] };
        --dashboard-colors-hover-border-color: ${ this.modelWithColors["hover-border-color"] };
        --dashboard-colors-hover-icon-color: ${ this.modelWithColors["hover-icon-color"] };
      }
    `;
  }

  getStyles_faq() {
    return `
      .dashboard {
        --faq-badge-bg: ${ this.modelWithColors["badge-bg"] };
        --faq-badge-color: ${ this.modelWithColors["badge-color"] };
        --faq-dashboard-primary-bg: ${ this.modelWithColors["dashboard-primary-bg"] };
        --faq-dashboard-primary-color: ${ this.modelWithColors["dashboard-primary-color"] };
        --faq-dashboard-success-bg: ${ this.modelWithColors["dashboard-success-bg"] };
        --faq-dashboard-success-color: ${ this.modelWithColors["dashboard-success-color"] };
        --faq-dashboard-success-dark-bg: ${ this.modelWithColors["dashboard-success-dark-bg"] };
        --faq-dashboard-success-dark-color: ${ this.modelWithColors["dashboard-success-dark-color"] };
        --faq-dashboard-danger-bg: ${ this.modelWithColors["dashboard-danger-bg"] };
        --faq-dashboard-danger-color: ${ this.modelWithColors["dashboard-danger-color"] };
        --faq-dashboard-danger-dark-bg: ${ this.modelWithColors["dashboard-danger-dark-bg"] };
        --faq-dashboard-danger-dark-color: ${ this.modelWithColors["dashboard-danger-dark-color"] };
        --faq-dashboard-info-bg: ${ this.modelWithColors["dashboard-info-bg"] };
        --faq-dashboard-info-color: ${ this.modelWithColors["dashboard-info-color"] };
        --faq-dashboard-info-dark-bg: ${ this.modelWithColors["dashboard-info-dark-bg"] };
        --faq-dashboard-info-dark-color: ${ this.modelWithColors["dashboard-info-dark-color"] };
        --faq-dashboard-warning-bg: ${ this.modelWithColors["dashboard-warning-bg"] };
        --faq-dashboard-warning-color: ${ this.modelWithColors["dashboard-warning-color"] };
        --faq-dashboard-help-bg: ${ this.modelWithColors["dashboard-help-bg"] };
        --faq-dashboard-help-color: ${ this.modelWithColors["dashboard-help-color"] };
        --faq-dashboard-help-inverse-bg: ${ this.modelWithColors["dashboard-help-inverse-bg"] };
        --faq-dashboard-help-inverse-color: ${ this.modelWithColors["dashboard-help-inverse-color"] };
        --faq-dashboard-disabled-bg: ${ this.modelWithColors["dashboard-disabled-bg"] };
        --faq-dashboard-disabled-color: ${ this.modelWithColors["dashboard-disabled-color"] };
      }
    `;
  }

  getStyles_btn_icon_switch() {
    return `
      .btn_icon_switch.a_btn_switch {
        --a_btn_color: ${ this.modelWithColors.color };
        --a_btn_bg: transparent;
        --a_btn_border_color: transparent;
        --a_btn_hover_color: ${ this.modelWithColors["hover-color"] };
        --a_btn_hover_bg: transparent;
        --a_btn_hover_border_color: transparent;
        --a_btn_focus_shadow_rgb: ${ this.modelWithColors["focus-shadow-rgb"] };
        --a_btn_active_color: ${ this.modelWithColors["active-color"] };
        --a_btn_active_bg: transparent;
        --a_btn_active_border_color: transparent;
        --a_btn_disabled_color: ${ this.modelWithColors["disabled-color"] };
        --a_btn_disabled_bg: transparent;
        --a_btn_disabled_border_color: transparent;
      }
      .btn_icon_switch.a_btn_switch_active {
        --a_btn_color: ${ this.modelWithColors["active-switch-color"] };
        --a_btn_bg: transparent;
        --a_btn_border_color: transparent;
        --a_btn_hover_color: ${ this.modelWithColors["active-switch-hover-color"] };
        --a_btn_hover_bg: transparent;
        --a_btn_hover_border_color: transparent;
        --a_btn_focus_shadow_rgb: ${ this.modelWithColors["active-switch-focus-shadow-rgb"] };
        --a_btn_active_color: ${ this.modelWithColors["active-switch-active-color"] };
        --a_btn_active_bg: transparent;
        --a_btn_active_border_color: transparent;
        --a_btn_disabled_color: ${ this.modelWithColors["active-switch-disabled-color"] };
        --a_btn_disabled_bg: transparent;
        --a_btn_disabled_border_color: transparent;
      }
    `;
  }

  getStyles_foerderfinderkategorien() {
    const COLORS = [
      "dark-red",
      "red",
      "green",
      "blue",
      "yellow",
      "orange",
      "purple",
      "pink",
      "turquoise",
    ];
    let filterColors = "";
    forEach(COLORS, color => {
      filterColors += `
          .filter-right .icon-conteiner img.${ color } {
            border-color: ${ this.modelWithColors[color] };
          }
        `;
    });
    let categoryColors = "";
    forEach(COLORS, color => {
      let colorHex = "";
      if (startsWith(this.modelWithColors[color], "var(--")) {
        colorHex = this.modelWithColors[color].substring(6).slice(0, -1);
        colorHex = this.globalVars[colorHex] ?
          this.globalVars[colorHex] :
          this.globalVars[`$${ colorHex }`];
      } else {
        colorHex = this.modelWithColors[color];
      }
      categoryColors += `
        .category.${ color } {
          --category-color: ${ this.modelWithColors[color] };
          --category-color-lighten-30: ${ mixWhite(colorHex, 30) };
          --category-color-lighten-15: ${ mixWhite(colorHex, 15) };
        }
      `;
    });

    return `
      .category {
        --category-default-color: ${ this.modelWithColors["default-color"] };
        --category-default-border-color: ${ this.modelWithColors["default-border-color"] };
      }
      ${ categoryColors }
      ${ filterColors }
    `;
  }

  getStyles_font() {
    return `
      :root {
        --primary-font-regular: ${ this.modelWithColors["primary-font-regular"] };
        --primary-font-bold: ${ this.modelWithColors["primary-font-bold"] };
        --secondary-font-regular: ${ this.modelWithColors["secondary-font-regular"] };
        --secondary-font-bold: ${ this.modelWithColors["secondary-font-bold"] };
        --header-font-bold: ${ this.modelWithColors["header-font-bold"] };
        --header-font-regular: ${ this.modelWithColors["header-font-regular"] };
      }
    `;
  }

  getStyles_form() {
    return `
      :root {
        --form-control-border: ${ this.modelWithColors["form-control-border"] };
        --form-control-border-color: ${ this.modelWithColors["form-control-border-color"] };
        --form-control-bg: ${ this.modelWithColors["form-control-bg"] };
        --form-control-color: ${ this.modelWithColors["form-control-color"] };
        --form-control-placeholder-color: ${ this.modelWithColors["form-control-placeholder-color"] };
        --form-control-focus-color: ${ this.modelWithColors["form-control-focus-color"] };
        --form-control-focus-border-color: ${ this.modelWithColors["form-control-focus-border-color"] };
        --form-control-error-bg: ${ this.modelWithColors["form-control-error-bg"] };
        --form-control-error-border-color: ${ this.modelWithColors["form-control-error-border-color"] };
      }
    `;
  }

  getStyles_h1_h2() {
    return `
      aside div.box h1 {
        color: ${ this.modelWithColors["h1-aside-color"] };
        font-size: ${ this.modelWithColors["h1-aside-font-size"] };
      }
      aside div.box h2 {
        color: ${ this.modelWithColors["h2-aside-color"] };
      }
      header.page-title h1 {
        font-size: ${ this.modelWithColors["h1-font-size"] };
      }
      h1 {
        font-size: ${ this.modelWithColors["h1-font-size"] };
        color: ${ this.modelWithColors["h1-global-color"] };
      }
      .form-step.form-step-box.active .form-step-detail h4 {
        color: ${ this.modelWithColors["h1-global-color"] };
      }
      h2 {
        font-size: ${ this.modelWithColors["h2-font-size"] };
        color: ${ this.modelWithColors["h2-global-color"] };
      }
    `;
  }

  getStyles_kf_perioden() {
    return `
      .kf_periode {
        --kf-perioden-border-color: ${ this.modelWithColors["kf-perioden-border-color"] };
        --kf-perioden-border-width: ${ this.modelWithColors["kf-perioden-border-width"] };
        --kf-perioden-border-weak-color: ${ this.modelWithColors["kf-perioden-border-weak-color"] };
        --kf-perioden-border-weak-width: ${ this.modelWithColors["kf-perioden-border-weak-width"] };
        --kf-perioden-header-bg: ${ this.modelWithColors["kf-perioden-header-bg"] };
        --kf-perioden-header-color: ${ this.modelWithColors["kf-perioden-header-color"] };
      }
    `;
  }

  getStyles_map() {
    return `
      .marker-cluster.dias_marker_cluster {
        --map-marker-bg: ${ this.modelWithColors["map-marker-bg"] };
        --map-marker-color: ${ this.modelWithColors["map-marker-color"] };
      }
    `;
  }

  getStyles_form_step() {
    return `
      .form-step {
        --form-step-bg: ${ this.modelWithColors["form-step-bg"] };
        --form-step-active-bg: ${ this.modelWithColors["form-step-active-bg"] };
        --form-step-header-bg: ${ this.modelWithColors["form-step-header-bg"] };
        --form-step-header-focus-text-decoration: ${ this.modelWithColors["form-step-header-focus-text-decoration"] };
        --form-step-header-border-color: ${ this.modelWithColors["form-step-header-border-color"] };
        --form-step-header-color: ${ this.modelWithColors["form-step-header-color"] };
        --form-step-header-hover-color: ${ this.modelWithColors["form-step-header-hover-color"] };
        --form-step-header-hover-bg: ${ this.modelWithColors["form-step-header-hover-bg"] };
        --form-step-header-hover-border-color: ${ this.modelWithColors["form-step-header-hover-border-color"] };
        --form-step-header-active-color: ${ this.modelWithColors["form-step-header-active-color"] };
        --form-step-header-active-bg: ${ this.modelWithColors["form-step-header-active-bg"] };
        --form-step-header-active-border-color: ${ this.modelWithColors["form-step-header-active-border-color"] };
        --form-step-edit-header-color: ${ this.modelWithColors["form-step-edit-header-color"] };
        --form-step-edit-hover-bg: ${ this.modelWithColors["form-step-edit-hover-bg"] };
        --form-step-detail-border-color: ${ this.modelWithColors["form-step-detail-border-color"] };
        --form-step-detail-edit-border-color: ${ this.modelWithColors["form-step-detail-edit-border-color"] };
        --form-step-detail-hover-bg: ${ this.modelWithColors["form-step-detail-hover-bg"] };
        --form-step-detail-edit-bg: ${ this.modelWithColors["form-step-detail-edit-bg"] };
        --form-step-detail-info-border: ${ this.modelWithColors["form-step-detail-info-border"] };
        --form-step-detail-info-border-color: ${ this.modelWithColors["form-step-detail-info-border-color"] };
      }
      .formstep_detail {
        --form-step-detail-border: ${ this.modelWithColors["form-step-detail-border"] };
        --form-step-detail-border-color: ${ this.modelWithColors["form-step-detail-border-color"] };
        --form-step-detail-bg: ${ this.modelWithColors["form-step-detail-bg"] };
        --form-step-detail-body-bg: ${ this.modelWithColors["form-step-detail-body-bg"] };
        --form-step-detail-padding: ${ this.modelWithColors["form-step-detail-padding"] };
        --form-step-detail-hover-bg: ${ this.modelWithColors["form-step-detail-hover-bg"] };
        --form-step-detail-hover-body-bg: ${ this.modelWithColors["form-step-detail-hover-body-bg"] };
        --form-step-detail-edit-bg: ${ this.modelWithColors["form-step-detail-edit-bg"] };
        --form-step-detail-edit-body-bg: ${ this.modelWithColors["form-step-detail-edit-body-bg"] };
        --form-step-collapse-header-hover-bg: ${ this.modelWithColors["form-step-collapse-header-hover-bg"] };
        --form-step-collapse-header-hover-color: ${ this.modelWithColors["form-step-collapse-header-hover-color"] };
        --form-step-collapse-show-header-bg: ${ this.modelWithColors["form-step-collapse-show-header-bg"] };
        --form-step-collapse-show-header-color: ${ this.modelWithColors["form-step-collapse-show-header-color"] };
        --form-step-collapse-show-border-left: ${ this.modelWithColors["form-step-collapse-show-border-left"] };
        --form-step-collapse-show-border-left-color: ${ this.modelWithColors["form-step-collapse-show-border-left-color"] };
      }
      .edit_box__header_hover,
      .edit_box__footer_hover {
        background-color: ${ this.modelWithColors["form-step-header-hover-bg"] };
      }
      .icon-collapse,
      div.newdocument.border-hover {
        --form-step-edit-header-color: ${ this.modelWithColors["form-step-edit-header-color"] };
      }
      table.list.table-dokumente,
      .position-form {
        --form-step-edit-hover-bg: ${ this.modelWithColors["form-step-edit-hover-bg"] };
        --form-step-detail-edit-bg: ${ this.modelWithColors["form-step-detail-edit-bg"] };
      }
      .notes {
        --form-step-edit-hover-bg: ${ this.modelWithColors["form-step-edit-hover-bg"] };
        --form-step-detail-hover-bg: ${ this.modelWithColors["form-step-detail-hover-bg"] };
        --form-step-detail-edit-bg: ${ this.modelWithColors["form-step-detail-edit-bg"] };
      }
      .edit_box {
        background-color: ${ this.modelWithColors["form-step-detail-edit-bg"] };
      }
    `;
  }

  getStyles_page_main() {
    return `
      .page_main,
      .page_main_full {
        padding-top: ${ this.modelWithColors["page-main-padding-top"] };
        padding-right: ${ this.modelWithColors["page-main-padding-right"] };
        padding-bottom: ${ this.modelWithColors["page-main-padding-bottom"] };
        padding-left: ${ this.modelWithColors["page-main-padding-left"] };
      }
      .page_main__header {
        padding-top: ${ this.modelWithColors["page-main-header-padding-top"] };
        padding-right: ${ this.modelWithColors["page-main-header-padding-right"] };
        padding-bottom: ${ this.modelWithColors["page-main-header-padding-bottom"] };
        padding-left: ${ this.modelWithColors["page-main-header-padding-left"] };
      }
      .page_main_h1 {
        color: ${ this.modelWithColors["page-main-header-h1-color"] };
      }
      .page_main_h2,
      .a_table__top_panel__label,
      .table_parent__header h2 {
        color: ${ this.modelWithColors["page-main-header-h2-color"] };
      }
      .page_main__actions__link_back {
        color: ${ this.modelWithColors["page-main-header-link-back-color"] };
      }
      .page_main__actions {
        padding-top: ${ this.modelWithColors["page-main-actions-padding-top"] };
        padding-right: ${ this.modelWithColors["page-main-actions-padding-right"] };
        padding-bottom: ${ this.modelWithColors["page-main-actions-padding-bottom"] };
        padding-left: ${ this.modelWithColors["page-main-actions-padding-left"] };
      }
      .page_main__body {
        padding-top: ${ this.modelWithColors["page-main-body-padding-top"] };
        padding-right: ${ this.modelWithColors["page-main-body-padding-right"] };
        padding-bottom: ${ this.modelWithColors["page-main-body-padding-bottom"] };
        padding-left: ${ this.modelWithColors["page-main-body-padding-left"] };
        background-color: ${ this.modelWithColors["page-main-body-bg"] };
      }
    `;
  }
  getStyles_panel_context() {
    return `
      .panel_context {
        --panel-context-bg: ${ this.modelWithColors["panel-context-bg"] };
        --panel-context-header-border: ${ this.modelWithColors["panel-context-header-border"] };
        --panel-context-header-border-color: ${ this.modelWithColors["panel-context-header-border-color"] };
        --panel-context-header-color: ${ this.modelWithColors["panel-context-header-color"] };
        --panel-context-header-bg: ${ this.modelWithColors["panel-context-header-bg"] };
        --panel-context-body-padding-top: ${ this.modelWithColors["panel-context-body-padding-top"] };
        --panel-context-body-padding-right: ${ this.modelWithColors["panel-context-body-padding-right"] };
        --panel-context-body-padding-bottom: ${ this.modelWithColors["panel-context-body-padding-bottom"] };
        --panel-context-body-padding-left: ${ this.modelWithColors["panel-context-body-padding-left"] };
        --panel-context-active-bg: ${ this.modelWithColors["panel-context-active-bg"] };
        --panel-context-active-header-bg: ${ this.modelWithColors["panel-context-active-header-bg"] };
        --panel-context-active-header-color: ${ this.modelWithColors["panel-context-active-header-color"] };
        --panel-context-active-header-border: ${ this.modelWithColors["panel-context-active-header-border"] };
        --panel-context-active-header-border-color: ${ this.modelWithColors["panel-context-active-header-border-color"] };
        --panel-context-hover-header-bg: ${ this.modelWithColors["panel-context-hover-header-bg"] };
        --panel-context-hover-header-border: ${ this.modelWithColors["panel-context-hover-header-border"] };
        --panel-context-hover-header-border-color: ${ this.modelWithColors["panel-context-hover-header-border-color"] };
        --panel-context-hover-header-color: ${ this.modelWithColors["panel-context-hover-header-color"] };
        --panel-context-focus-header-text-decoration: ${ this.modelWithColors["panel-context-focus-header-text-decoration"] };
        --panel-context-mark-bg: ${ this.modelWithColors["panel-context-mark-bg"] };
        --panel-context-mark-header-bg: ${ this.modelWithColors["panel-context-mark-header-bg"] };
        --panel-context-mark-header-hover-bg: ${ this.modelWithColors["panel-context-mark-header-hover-bg"] };
        --panel-context-mark-header-color: ${ this.modelWithColors["panel-context-mark-header-color"] };
        --panel-context-mark-header-hover-color: ${ this.modelWithColors["panel-context-mark-header-hover-color"] };
        --panel-context-icons-box-color: ${ this.modelWithColors["panel-context-icons-box-color"] };
      }
    `;
  }
  getStyles_personalkosten_perioden() {
    return `
      .p_kosten_perioden {
        --personalkosten-perioden-d-zeitraum-bg: ${ this.modelWithColors["personalkosten-perioden-d-zeitraum-bg"] };
        --personalkosten-perioden-d-zeitraum-vertrag-bg: ${ this.modelWithColors["personalkosten-perioden-d-zeitraum-vertrag-bg"] };
        --personalkosten-perioden-d-zeitraum-vertrag-bg: ${ this.modelWithColors["personalkosten-perioden-d-zeitraum-vertrag-bg"] };
        --personalkosten-perioden-header-td-bg: ${ this.modelWithColors["personalkosten-perioden-header-td-bg"] };
        --personalkosten-perioden-footer-td-bg: ${ this.modelWithColors["personalkosten-perioden-footer-td-bg"] };
        --personalkosten-perioden-position-bg: ${ this.modelWithColors["personalkosten-perioden-position-bg"] };
        --personalkosten-perioden-position-color: ${ this.modelWithColors["personalkosten-perioden-position-color"] };
        --personalkosten-perioden-position-border: ${ this.modelWithColors["personalkosten-perioden-position-border"] };
        --personalkosten-perioden-position-border-color: ${ this.modelWithColors["personalkosten-perioden-position-border-color"] };
        --personalkosten-perioden-position-td-bg: ${ this.modelWithColors["personalkosten-perioden-position-td-bg"] };
        --personalkosten-perioden-person-bg: ${ this.modelWithColors["personalkosten-perioden-person-bg"] };
        --personalkosten-perioden-person-color: ${ this.modelWithColors["personalkosten-perioden-person-color"] };
        --personalkosten-perioden-person-border: ${ this.modelWithColors["personalkosten-perioden-person-border"] };
        --personalkosten-perioden-person-border-color: ${ this.modelWithColors["personalkosten-perioden-person-border-color"] };
        --personalkosten-perioden-person-td-bg: ${ this.modelWithColors["personalkosten-perioden-person-td-bg"] };
        --personalkosten-perioden-beleg-bg: ${ this.modelWithColors["personalkosten-perioden-beleg-bg"] };
        --personalkosten-perioden-beleg-color: ${ this.modelWithColors["personalkosten-perioden-beleg-color"] };
        --personalkosten-perioden-beleg-border: ${ this.modelWithColors["personalkosten-perioden-beleg-border"] };
        --personalkosten-perioden-beleg-border-color: ${ this.modelWithColors["personalkosten-perioden-beleg-border-color"] };
        --personalkosten-perioden-beleg-td-bg: ${ this.modelWithColors["personalkosten-perioden-beleg-td-bg"] };
      }
      .beleg_annerkant {
        background-color: ${ this.modelWithColors["personalkosten-perioden-beleg-annerkant-bg"] };
        border-color: ${ this.modelWithColors["personalkosten-perioden-beleg-annerkant-border-color"] };
      }

      .beleg_abgelehnt {
        background-color: ${ this.modelWithColors["personalkosten-perioden-beleg-abgelehnt-bg"] };
        border-color: ${ this.modelWithColors["personalkosten-perioden-beleg-abgelehnt-border-color"] };
      }
    `;
  }

  getStyles_select() {
    return `
      .ui_select,
      .a_select {
        --select-caret-color: ${ this.modelWithColors["select-caret-color"] };
        --select-caret-bg: ${ this.modelWithColors["select-caret-bg"] };
      }
      .dropdown_menu,
      .ui_select__menu {
      --select-menu-link-hover-bg: ${ this.modelWithColors["select-menu-link-hover-bg"] };
        --select-menu-link-hover-color: ${ this.modelWithColors["select-menu-link-hover-color"] };
      }
    `;
  }

  getStyles_status() {
    return `
      .status-done {
        color: ${ this.modelWithColors["status-done-color"] };
      }
      .status-open {
        color: ${ this.modelWithColors["status-open-color"] };
      }
      .status-edit {
        color: ${ this.modelWithColors["status-edit-color"] };
      }
    `;
  }

  getStyles_switch() {
    return `
      .switch_button {
        --a_switch_button_bg: ${ this.modelWithColors["switch-bg"] };
        --a_switch_button_active_bg: ${ this.modelWithColors["switch-active-bg"] };
        --a_switch_button_inactive_bg: ${ this.modelWithColors["switch-inactive-bg"] };
        --a_switch_hover_focus_bg: ${ this.modelWithColors["switch-hover-focus-bg"] };
        --a_switch_button_disabled_bg: ${ this.modelWithColors["switch-disabled-bg"] };
        --a_switch_button_disabled_inner_bg: ${ this.modelWithColors["switch-disabled-inside-bg"] };
        --a_switch_button_disabled_border_color: ${ this.modelWithColors["switch-disabled-bg"] };
      }
    `;
  }

  getStyles_table() {
    const tableBg = "#ffffff";

    return `
      .table_parent,
      .a_table__parent {
        --a_table_bg: ${ tableBg };
        --a_table_row_hover_bg: ${ this.modelWithColors["table-row-hover-bg"] };
        --a_table_row_preview_open_bg: ${ this.modelWithColors["table-row-focus-bg"] };
        --a_table_row_preview_was_open_bg: ${ this.modelWithColors["table-row-was-focused-bg"] };
        --a_table_preview_right_header_bg: ${ this.modelWithColors["table-header-bg"] };
        --table-header-bg: ${ this.modelWithColors["table-header-bg"] };
        --table-header-color: ${ this.modelWithColors["table-header-color"] };
        --table-header-sort-active-bg: ${ this.modelWithColors["table-header-sort-active-bg"] };
        --table-horizontal-border-color: ${ this.modelWithColors["table-horizontal-border-color"] };
        --table-vertical-border-color: ${ this.modelWithColors["table-vertical-border-color"] };
      }
      .a_pagination__parent {
        --a_pagination_bg: ${ this.modelWithColors["table-pagination-bg"] };
        --a_pagination_link_color: ${ this.modelWithColors["table-pagination-primary-color"] };
        --a_pagination_link_active_bg: ${ this.modelWithColors["table-pagination-primary-color"] };
        --a_pagination_link_active_border_color: ${ this.modelWithColors["table-pagination-primary-color"] };
        --a_pagination_link_bg: ${ this.modelWithColors["table-pagination-secondary-color"] };
        --a_pagination_link_active_color: ${ this.modelWithColors["table-pagination-secondary-color"] };
      }
      table.list {
        --table-row-focus-bg: ${ this.modelWithColors["table-row-focus-bg"] };
        --table-row-hover-bg: ${ this.modelWithColors["table-row-hover-bg"] };
      }
      .list-wrapper {
        --table-bg: ${ tableBg };
      }
      div.position,
      .form-step,
      .a_list_group_striped {
        --table-row-hover-bg: ${ this.modelWithColors["table-row-hover-bg"] };
      }
      .dashboard {
        --table-row-hover-bg: ${ this.modelWithColors["table-row-hover-bg"] };
      }
    `;
  }

  getStyles_table_symbol() {
    return `
      .table_symbol {
        --table-symbol-button-bg: ${ this.modelWithColors["table-symbol-button-bg"] };
        --table-symbol-button-border: ${ this.modelWithColors["table-symbol-button-border"] };
        --table-symbol-button-border-color: ${ this.modelWithColors["table-symbol-button-border-color"] };
        --table-symbol-button-color: ${ this.modelWithColors["table-symbol-button-color"] };
        --table-symbol-button-hover-bg: ${ this.modelWithColors["table-symbol-button-hover-bg"] };
        --table-symbol-button-hover-color: ${ this.modelWithColors["table-symbol-button-hover-color"] };
        background-color: ${ this.modelWithColors["table-symbol-bg"] };
      }
      .table-details {
        background-color: ${ this.modelWithColors["table-symbol-detail-bg"] };
        border: ${ this.modelWithColors["table-symbol-detail-border"] } ${ this.modelWithColors["table-symbol-detail-border-color"] };
      }
      .table-details h3,
      .table-details .h3-color {
        color: ${ this.modelWithColors["table-symbol-detail-h3-color"] };
      }
      .table-details .icon {
        color: ${ this.modelWithColors["table-symbol-detail-icon-color"] };
      }
      .table-details .block-right,
      .table-details .block-top {
        border: ${ this.modelWithColors["table-symbol-detail-block-center-border"] } ${ this.modelWithColors["table-symbol-detail-block-center-border-color"] };
      }
      .secondary-details {
        background-color: ${ this.modelWithColors["table-detail-secondary-bg"] };
        border: ${ this.modelWithColors["table-detail-secondary-border"] } ${ this.modelWithColors["table-detail-secondary-border-color"] };
      }
      .tertiary-details {
        background-color: ${ this.modelWithColors["table-detail-tertiary-bg"] };
        border: ${ this.modelWithColors["table-detail-tertiary-border"] } ${ this.modelWithColors["table-detail-tertiary-border-color"] };
      }
    `;
  }

  getStyles_versionsvergleich() {
    return `
      .panel_context__icons_box__version_icon.active {
        background-color: ${ this.modelWithColors["versionsvergleich-stark-color"] };
      }
      .version_color,
      .version-color {
        color: ${ this.modelWithColors["versionsvergleich-stark-color"] } !important;
      }
      .version-bg,
      .version_bg {
        background-color: ${ this.modelWithColors["versionsvergleich-bg"] } !important;
      }
      .version_bg_light {
        background-color: ${ this.modelWithColors["versionsvergleich-bg-light"] } !important;
      }
      .version_position {
        background-color: ${ this.modelWithColors["versionsvergleich-position-bg"] } !important;
      }
    `;
  }

  getStyles_wf_statusgruppen() {
    return `
      .st_gr_antrag {
        background-color: ${ this.modelWithColors["wf-statusgruppen-antrag-bg"] };
      }
      .st_gr_antrag .st_gr__line {
        background-color: ${ this.modelWithColors["wf-statusgruppen-antrag-line-bg"] };
      }
      .st_gr_antrag .st_gr__round {
        border-color: ${ this.modelWithColors["wf-statusgruppen-antrag-line-bg"] };
      }
      .st_gr_antrag .st_gr__line_active {
        background-color: ${ this.modelWithColors["wf-statusgruppen-antrag-active-line-bg"] };
      }
      .st_gr_antrag .st_gr__round_active {
        border-color: ${ this.modelWithColors["wf-statusgruppen-antrag-active-line-bg"] };
      }
      .st_gr_antrag .st_gr__round_active__footer {
        background-color: ${ this.modelWithColors["wf-statusgruppen-antrag-active-line-bg"] };
      }
      .st_gr_vertrag {
        background-color: ${ this.modelWithColors["wf-statusgruppen-vertrag-bg"] };
      }
      .st_gr_vertrag .st_gr__line {
        background-color: ${ this.modelWithColors["wf-statusgruppen-vertrag-line-bg"] };
      }
      .st_gr_vertrag .st_gr__round {
        border-color: ${ this.modelWithColors["wf-statusgruppen-vertrag-line-bg"] };
      }
      .st_gr_vertrag .st_gr__line_active {
        background-color: ${ this.modelWithColors["wf-statusgruppen-vertrag-active-line-bg"] };
      }
      .st_gr_vertrag .st_gr__round_active {
        border-color: ${ this.modelWithColors["wf-statusgruppen-vertrag-active-line-bg"] };
      }
      .st_gr_vertrag .st_gr__round_active__footer {
        background-color: ${ this.modelWithColors["wf-statusgruppen-vertrag-active-line-bg"] };
      }
      .st_gr__line {
        background-color: ${ this.modelWithColors["wf-statusgruppen-line-bg"] };
      }
      .st_gr__round_active {
        color: ${ this.modelWithColors["wf-statusgruppen-active-round-color"] };
        border-color: ${ this.modelWithColors["wf-statusgruppen-active-round-border-color"] };
      }
      .st_gr_in_header .st_gr__round {
        color: ${ this.modelWithColors["wf-statusgruppen-active-round-color"] };
      }
      .st_gr_success .st_gr__line {
        background-color: ${ this.modelWithColors["wf-statusgruppen-success-line-bg"] };
      }
      .st_gr_success .st_gr__round_active__footer {
        background-color: ${ this.modelWithColors["wf-statusgruppen-success-active-round-footer-bg"] };
      }
      .st_gr_success .st_gr__round {
        border-color: ${ this.modelWithColors["wf-statusgruppen-success-active-round-border-color"] };
      }
      .st_gr_warning .st_gr__line {
        background-color: ${ this.modelWithColors["wf-statusgruppen-warning-line-bg"] };
      }
      .st_gr_warning .st_gr__round_active__footer {
        background-color: ${ this.modelWithColors["wf-statusgruppen-warning-active-round-footer-bg"] };
      }
      .st_gr_warning .st_gr__round {
        border-color: ${ this.modelWithColors["wf-statusgruppen-warning-active-round-border-color"] };
      }
      .st_gr__round_active__footer {
        background-color: ${ this.modelWithColors["wf-statusgruppen-active-round-footer-bg"] };
      }
    `;
  }

  getStyles_wizard() {
    return `
      .a_btn.wizard__steps__button_active {
        background-color: ${ this.modelWithColors["wizard-steps-button-active-bg"] };
        color: ${ this.modelWithColors["wizard-steps-button-active-color"] };
      }

      .a_btn.wizard__steps__button_before {
        background-color: ${ this.modelWithColors["wizard-steps-button-before-bg"] };
      }
    `;
  }

  getStyles_rest() {
    return `
      .a_search_highlight {
         background-color: ${ this.modelWithColors["highlight-bg-color"] };
         color: ${ this.modelWithColors["highlight-text-color"] };
         font-weight: ${ this.modelWithColors["highlight-font-weight"] };
         border-width: ${ this.modelWithColors["highlight-border-width"] };
         border-style: ${ this.modelWithColors["highlight-border-style"] };
         border-color: ${ this.modelWithColors["highlight-border-color"] };
      }
      .box_hover:hover {
        background-color: ${ this.modelWithColors["box-hover-bg"] };
      }
      .black-link a {
        color: ${ this.modelWithColors["link-stark-color"] };
      }
      .attention {
        background: ${ this.modelWithColors["attention-color"] } !important;
      }
      .public-pages {
        width: ${ this.modelWithColors["aside-width-public-pages"] };
      }
      .badge_square,
      .badge:not([class*="badge-"]) {
        background-color: ${ this.modelWithColors["badge-background-color"] };
      }
      aside div.box {
        border: 1px solid ${ this.modelWithColors["box-border-color"] };
      }
      .flex-row-inline .inline-box-first {
        width: calc(100% - ${ this.modelWithColors["inline-box-last-width"] });
      }
      .flex-row-inline .inline-box-last {
        width: ${ this.modelWithColors["inline-box-last-width"] };
      }
      .newdocument {
        --newdocument-color: ${ this.modelWithColors["newdocument-color"] };
      }
      div.noentry {
        border: 1px solid ${ this.modelWithColors["noentry-border-color"] };
      }
      .clearfix.clearfix-hover,
      .position {
        --position-hover-bg: ${ this.modelWithColors["position-hover-bg"] };
      }
      .position-form {
        --position-header-hover-color: ${ this.modelWithColors["position-header-hover-color"] };
      }
      @media (min-width: 992px) {
        .wrapper-content {
          padding: 0 10px ${ this.modelWithColors["wrapper-content-padding-bottom"] } 0;
        }
      }
      .login_box {
        background-color: ${ this.modelWithColors["login-box-bg"] };
      }
      #table_filters > button.details-toggle {
        height: ${ this.modelWithColors["filter-height"] };
      }
      .dias-filter,
      .search-input-group {
        --filter-height: ${ this.modelWithColors["filter-height"] };
      }
      @media (min-width: 768px) {
        #page-wrapper {
          min-height: ${ this.modelWithColors["page-wrapper-min-height"] };
        }
      }
    `;
  }

  getStyles_global() {
    let focusStylesString = "";
    if (this.modelWithColors["use-browser-focus"]) {
      focusStylesString = `
      .btn:focus,
      .form_control:focus,
      .wizard__steps__button:focus,
      .not-button:focus,
      .a_form_control:focus,
      .a_form_element_number_controls .a_btn_group>.aloha_btn:focus{
        outline: 2px solid black !important;
        box-shadow: 0px 0 0 2px white !important;
        outline-offset: -2px;
      }
      .box_highlight,
      a:focus,
      .category .category__button:focus,
      .pux_datepicker__panel_focus,
      .panel_menu__link_dropdown:focus,
      .panel_menu__dropdown_menu__link:focus,
      .ui_select__element_clickable:focus,
      .pux_datepicker__panel__cell_focus,
      .pux_datepicker__calendar__tab:focus,
      .invisible-label .custom-control-input:focus ~ .custom-control-label::before,
      .page-link:focus
       {
        outline: 2px solid black;
        box-shadow: 0px 0 0 2px white;
        outline-offset: -2px;
      }
      `;
    } else {
      focusStylesString = `
      .btn:focus,
      .form_control:focus,
      .wizard__steps__button:focus,
      .not-button:focus {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors["shadow-focus-color"] } !important;
      }
      .box_highlight {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors["shadow-focus-color"] };
      }
      a:focus {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors["shadow-focus-color"] };
      }
      .category .category__button:focus,
      .pux_datepicker__panel_focus {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors["shadow-focus-color"] };
      }
      .panel_menu__link_dropdown:focus,
      .panel_menu__dropdown_menu__link:focus {
        box-shadow: inset 0 0 0 4px ${ this.modelWithColors["shadow-focus-color"] };
      }
      .ui_select__element_clickable:focus,
      .pux_datepicker__panel__cell_focus,
      .pux_datepicker__calendar__tab:focus {
        box-shadow: inset 0 0 1px 3px ${ this.modelWithColors["shadow-focus-color"] };
      }
      .invisible-label .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors["shadow-focus-color"] };
      }
      .page-link:focus {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors["shadow-focus-color"] };
      }
      .a_form_control:focus {
        box-shadow: 0 0 1px 2px ${ this.modelWithColors["shadow-focus-color"] } !important;
      }
      .a_form_element_number_controls .a_btn_group>.aloha_btn:focus {
        box-shadow: 0 0 1px 2px ${ this.modelWithColors["shadow-focus-color"] } !important;
      }
      `;
    }

    return `
      .page-wrapper,
      .page_main__actions {
        background-color: ${ this.modelWithColors.bg };
      }
      body {
        color: ${ this.modelWithColors.color };
      }
      ${ focusStylesString }
    `;
  }

  getStyles_gutachten() {
    let ergebnisauswahlVars = "";
    let ergebnisVars = "";
    forEach(GUTACHTEN_COLORS_BASE, (_, key) => {
      ergebnisauswahlVars += `.gutachten-ergebnisauswahl.with-styling .${ key } {
          --gutachten-color: ${ this.modelWithColors[key] };
          --gutachten_fff_90: ${ this.modelWithColors[`${ key }_fff_90`] };
          --gutachten_fff_80: ${ this.modelWithColors[`${ key }_fff_80`] };
          --gutachten_fff_50: ${ this.modelWithColors[`${ key }_fff_50`] };
          --gutachten_fff_25: ${ this.modelWithColors[`${ key }_fff_25`] };
        }`;
      ergebnisVars += `.gutachten-ergebnis .${ key } {
          --gutachten-color: ${ this.modelWithColors[key] };
          --gutachten_fff_90: ${ this.modelWithColors[`${ key }_fff_90`] };
        }
        .gutachten-ergebnis .minimal-gutachten-ergebnis .${ key } {
          --gutachten_fff_90: ${ this.modelWithColors[`${ key }_fff_90`] };
          --gutachten_fff_50: ${ this.modelWithColors[`${ key }_fff_50`] };
        }`;
    });
    return `
      ${ ergebnisauswahlVars }
      ${ ergebnisVars }
    `;
  }
}

export function getVarsWithMapping(config = {}) {
  const VARS = {};
  forEach(config, (value, key) => {
    const LOCAL_KEY = VARS_MAPPING[key] || key;
    VARS[LOCAL_KEY] = value.value;
  });
  return VARS;
}

export function addStylesInHeader({
  config,
}) {
  if (!config) {
    return;
  }
  const COLORS = getVarsWithMapping(config.colors);
  const BORDER_RADIUS = getVarsWithMapping(config.border_radius);
  const GLOBAL_VARS = assign({}, COLORS, BORDER_RADIUS);
  
  new Styles({
    model: GLOBAL_VARS,
    globalVars: GLOBAL_VARS,
    name: "vars",
    type: "global",
  });
  new Styles({
    model: config.primary_colors,
    globalVars: GLOBAL_VARS,
    name: "primary_colors",
    type: "global",
  });
  new Styles({
    model: config.global,
    globalVars: GLOBAL_VARS,
    name: "global",
    type: "main",
  });
  new Styles({
    model: config.buttons.all,
    globalVars: GLOBAL_VARS,
    name: "button_all",
    type: "main",
  });
  ["primary", "secondary", "tertiary", "switch", "switch_active"].forEach(buttonType => {
    new Styles({
      model: config.buttons[buttonType],
      globalVars: GLOBAL_VARS,
      name: "button",
      type: buttonType,
    });
  });
  // ["primary", "secondary", "tertiary", "success", "info", "warning", "danger"].forEach(alertType => {
  //   new Styles({
  //     model: config.alerts[alertType],
  //     globalVars: GLOBAL_VARS,
  //     name: "alert",
  //     type: alertType,
  //   });
  // });
  new Styles({
    model: config.antrag_steps,
    globalVars: GLOBAL_VARS,
    name: "antrag_steps",
    type: "main",
  });
  new Styles({
    model: config.beleg,
    globalVars: GLOBAL_VARS,
    name: "beleg",
    type: "main",
  });
  new Styles({
    model: config.btn_icon_switch,
    globalVars: GLOBAL_VARS,
    name: "btn_icon_switch",
    type: "main",
  });
  forEach(config.chart_colors, (configChart, chartType) => {
    new Styles({
      model: configChart,
      globalVars: GLOBAL_VARS,
      name: "chart_colors",
      type: chartType,
    });
  });
  new Styles({
    model: config.checkbox,
    globalVars: GLOBAL_VARS,
    name: "checkbox",
    type: "main",
  });
  new Styles({
    model: config.dashboard,
    globalVars: GLOBAL_VARS,
    name: "dashboard",
    type: "main",
  });
  forEach(config.dashboard_colors, (configDashboardColor, color) => {
    new Styles({
      model: configDashboardColor,
      globalVars: GLOBAL_VARS,
      name: "dashboard_colors",
      type: color,
    });
  });
  new Styles({
    model: config.faq,
    globalVars: GLOBAL_VARS,
    name: "faq",
    type: "main",
  });
  new Styles({
    model: config.foerderfinderkategorien,
    globalVars: GLOBAL_VARS,
    name: "foerderfinderkategorien",
    type: "main",
  });
  new Styles({
    model: config.font,
    globalVars: GLOBAL_VARS,
    name: "font",
    type: "main",
  });
  new Styles({
    model: config.footer,
    globalVars: GLOBAL_VARS,
    name: "footer",
    type: "main",
  });
  new Styles({
    model: config.form,
    globalVars: GLOBAL_VARS,
    name: "form",
    type: "main",
  });
  new Styles({
    model: config.form_step,
    globalVars: GLOBAL_VARS,
    name: "form_step",
    type: "main",
  });
  new Styles({
    model: config.h1_h2,
    globalVars: GLOBAL_VARS,
    name: "h1_h2",
    type: "main",
  });
  new Styles({
    model: config.kf_perioden,
    globalVars: GLOBAL_VARS,
    name: "kf_perioden",
    type: "main",
  });
  new Styles({
    model: config.link,
    globalVars: COLORS,
    name: "link",
    type: "main",
  });
  new Styles({
    model: config.map,
    globalVars: GLOBAL_VARS,
    name: "map",
    type: "main",
  });
  new Styles({
    model: config.navbar,
    globalVars: GLOBAL_VARS,
    name: "navbar",
    type: "main",
  });
  new Styles({
    model: config.page_main,
    globalVars: GLOBAL_VARS,
    name: "page_main",
    type: "main",
  });
  new Styles({
    model: config.panel_context,
    globalVars: GLOBAL_VARS,
    name: "panel_context",
    type: "main",
  });
  new Styles({
    model: config.personalkosten_perioden,
    globalVars: GLOBAL_VARS,
    name: "personalkosten_perioden",
    type: "main",
  });
  new Styles({
    model: config.proandi_menu,
    globalVars: GLOBAL_VARS,
    name: "proandi_menu",
    type: "main",
  });
  new Styles({
    model: config.rest,
    globalVars: GLOBAL_VARS,
    name: "rest",
    type: "main",
  });
  new Styles({
    model: config.select,
    globalVars: GLOBAL_VARS,
    name: "select",
    type: "main",
  });
  new Styles({
    model: config.status,
    globalVars: GLOBAL_VARS,
    name: "status",
    type: "main",
  });
  new Styles({
    model: config.switch,
    globalVars: GLOBAL_VARS,
    name: "switch",
    type: "main",
  });
  new Styles({
    model: config.table,
    globalVars: GLOBAL_VARS,
    name: "table",
    type: "main",
  });
  new Styles({
    model: config.table_symbol,
    globalVars: GLOBAL_VARS,
    name: "table_symbol",
    type: "main",
  });
  new Styles({
    model: config.tooltip,
    globalVars: GLOBAL_VARS,
    name: "tooltip",
    type: "main",
  });
  new Styles({
    model: config.versionsvergleich,
    globalVars: GLOBAL_VARS,
    name: "versionsvergleich",
    type: "main",
  });
  new Styles({
    model: config.wf_statusgruppen,
    globalVars: GLOBAL_VARS,
    name: "wf_statusgruppen",
    type: "main",
  });
  new Styles({
    model: config.wizard,
    globalVars: GLOBAL_VARS,
    name: "wizard",
    type: "main",
  });
  new Styles({
    model: getGutachtenColors(),
    globalVars: GLOBAL_VARS,
    name: "gutachten",
    type: "main",
  });
}

function getGutachtenColors() {
  const GUTACHTEN_COLORS = {};
  forEach(GUTACHTEN_COLORS_BASE, (value, key) => {
    GUTACHTEN_COLORS[key] = {
      value,
    };
    GUTACHTEN_COLORS[`${ key }_fff_90`] = {
      parent: value,
      mix: "white",
      percent: 90,
    };
    GUTACHTEN_COLORS[`${ key }_fff_80`] = {
      parent: value,
      mix: "white",
      percent: 80,
    };
    GUTACHTEN_COLORS[`${ key }_fff_50`] = {
      parent: value,
      mix: "white",
      percent: 50,
    };
    GUTACHTEN_COLORS[`${ key }_fff_25`] = {
      parent: value,
      mix: "white",
      percent: 25,
    };
  });

  return GUTACHTEN_COLORS;
}
